<template>
  <el-dialog
    title="添加外跳链接"
    :visible.sync="dialogJumpUrl.isVisible"
    width="35%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="jumpUrlBeforeClose"
  >
    <el-form :model="dialogJumpUrl" ref="refDialogJumpUrl" label-width="80px">
      <el-form-item
        label="链接地址"
        prop="url"
        :rules="[
          { required: true, message: '请输入链接地址', trigger: 'blur' },
          { type: 'url', message: '请输入正确的链接', trigger: ['blur', 'change'] },
        ]"
      >
        <el-input placeholder="请输入链接地址" v-model="dialogJumpUrl.url"> </el-input>
      </el-form-item>
      <el-form-item
        label="链接标题"
        prop="title"
        :rules="[{ required: true, message: '请输入链接标题', trigger: 'blur' }]"
      >
        <el-input placeholder="请输入链接标题" v-model="dialogJumpUrl.title" maxlength="8" show-word-limit> </el-input>
      </el-form-item>
      <el-form-item label="链接描述">
        <el-input placeholder="请输入链接标题" v-model="dialogJumpUrl.desc" maxlength="8" show-word-limit> </el-input>
      </el-form-item>
      <el-form-item label="链接图标">
        <div class="upload_samll delete">
          <div @click="importWord">
              <img
                :src="dialogJumpUrl.image || '../../../../../../assets/images/image_add.svg'"
                alt=""
                :style="{ width: dialogJumpUrl.image ? '' : '36px', height: dialogJumpUrl.image ? '' : '36px' }"
              />
              <span v-show="dialogJumpUrl.image" @click.stop="">
                  <i class="el-icon-delete" @click="deleteImg"></i>
              </span>
          </div>
          <!-- <span @click="importWord">重新上传</span> -->
        </div>
      </el-form-item>
      <input type="file" ref="fileInput" style="display: none" @change="batchImport" accept=".jpeg,.jpg,.png,.gif">
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="jumpUrlBeforeClose">取 消</el-button>
      <el-button @click="jumpUrlConfirm" type="primary">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { validateForm, sleep } from "@/utils/utils";
import { uploadFile } from "@/api/upload";

export default {
  name: "add-jump-url",
  data() {
    return {
      jumpUrlList: [], //外链列表
      dialogJumpUrl: {
        isVisible: false,
        url: "",
        title: "",
        desc: "",
        image: "",
      },
    };
  },
  components: {},
  computed: {},
  methods: {
    show(data) {
      this.dialogJumpUrl.isVisible = true;
      if (data != undefined) {
        this.dialogJumpUrl.url = data.url;
        this.dialogJumpUrl.title = data.title;
        this.dialogJumpUrl.desc = data.desc;
        this.dialogJumpUrl.image = data.image;
      }
    },
    //外跳链接弹框关闭
    jumpUrlBeforeClose() {
      this.dialogJumpUrl.isVisible = false;
      this.dialogJumpUrl.url = "";
      this.dialogJumpUrl.title = "";
      this.dialogJumpUrl.desc = "";
      this.dialogJumpUrl.image = "";
    },
    //插入iframe确认
    async jumpUrlConfirm() {
      await validateForm(this.$refs.refDialogJumpUrl);
      this.$emit("onJumpUrlChoose", this.dialogJumpUrl);
      this.jumpUrlBeforeClose();
    },
    importWord() {
      this.$refs.fileInput.click()
    },
    //图片上传前，检查大小是否超出
    imgBeforeUpload(file) {
        const isFileSizeFit = file.size / 1024 / 1024 < 5;
        if (!isFileSizeFit) {
            this.$message.error("图片大小不能超过 5MB!");
        }

        return isFileSizeFit;
    },
    batchImport(e) {
        const [file] = e.target.files;
        const checkSize = this.imgBeforeUpload(file)
        if (checkSize) {
            this.imgUploading(file)
        }
          // 清空input的值
        this.$refs.fileInput.value = ''
    },
     // 上传logo图片
    imgUploading(file) {
      const that = this
      uploadFile(file).then(async res => {
          if (!res || res.code != 200) {
              that.$message.error("图片上传失败！");
              return;
          }
          that.dialogJumpUrl.image = res.data[0].downUrl;
      });
    },
    deleteImg() {
      this.dialogJumpUrl.image = ''
    },
  },
};
</script>
<style lang="less" scoped>
.upload_samll {
    display: flex;
    align-items: flex-end;
    > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f8ff;
        height: 64px;
        width: 64px;
        > img {
            max-width: 100%;
            max-height: 100%;
        }
        > span {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(29, 29, 29, 0.7);
            opacity: 0;
            color: #fff;
            cursor: pointer;
            font-size: 22px;
            > i:hover {
                color: #FF9226;
            }
        }
    }
    > span {
        margin-left: 16px;
        font-size: 12px;
        color: #3472E8;
        cursor: pointer;
        &:hover {
            color: #1a61e9;
        }
    }
}
.delete:hover {
    > div {
        > span {
            opacity: 1;
        }
    }
}
</style>
