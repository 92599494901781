<template>
    <el-dialog
      :visible.sync="isDialogVisible"
      title="新增名片"
      width="1130px"
      top="8vh"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
    >
        <div class="business_card">
            <van-empty description="暂无数据" v-if="!cardList.length"></van-empty>
            <el-radio-group v-model="card_index" v-else>
                <el-radio-button :label="item.id" v-for="item in cardList" :key="item.id">
                    <div class="card_box_1" v-if="item.wxQrCode">
                        <div class="headerImg">
                            <img :src="item.headUrl" alt="头像">
                        </div>
                        <h3>{{ item.name }}</h3>
                        <span>{{ item.position }}</span>
                        <div class="qrCodeImg">
                            <img :src="item.wxQrCode" alt="">
                        </div>
                        <p>{{ item.company }}</p>
                        <div class="iphone">
                            <span>{{ item.telephone }}</span>
                            <img src="@/assets/images/interact_video/ico_tel.png" alt="">
                        </div>
                    </div>
                    <div class="card_box_2" v-else>
                        <div class="headerImg">
                            <img :src="item.headUrl" alt="头像">
                        </div>
                        <h3>{{ item.name }}</h3>
                        <span>{{ item.position }}</span>
                        <p>{{ item.company }}</p>
                        <div class="iphone">
                            <span>{{ item.telephone }}</span>
                            <img src="@/assets/images/interact_video/ico_tel.png" alt="">
                        </div>
                    </div>
                </el-radio-button>
            </el-radio-group>
            <div class="pagination" v-show="total > 8">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <div class="dialog-footer" slot="footer">
            <span @click="openEditCard" v-if="userInfo.remainTime > 0 || !userInfo.whiteStatus">添加名片</span>
            <span v-else></span>
            <div>
                <el-button @click="beforeClose">取消</el-button>
                <el-button type="primary" @click="confirm">确定</el-button>
            </div>
        </div>
        <EditCard ref="editCard" @getCardList="getCardList" />
    </el-dialog>
</template>
<script>
import EditCard from './editCard.vue'
export default {
    data: function() {
        return {
            isDialogVisible: false,
            cardList: [],
            card_index: '',
            pageSize: 8,
            pageNum: 1,
            total: 0,
            loading: false,
            userInfo: JSON.parse(localStorage.getItem('userInfo')) || {}, // 获取localStorage中的info
        }
    },
    components: {
        EditCard
    },
    methods: {
        openEditCard() {
            this.$refs.editCard.show()
        },
        async getCardList() {
            this.loading = true
            const params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            const data = await this.API.materialCenter.businessCardList(params);
            this.cardList = data.data.list;
            this.total = data.data.total;
            this.loading = false
        },
        // 分页
        handleCurrentChange(val) {
            this.pageNum = val
            this.getCardList()
        },
        show() {
            this.isDialogVisible = true
            if (this.cardList.length === 0) {
                this.getCardList()
            }
        },
        confirm() {
            this.$emit('onBusinessCardChoose', this.cardList.filter(item => item.id === this.card_index)[0])
            this.beforeClose()
        },
        // 关闭弹框
        beforeClose() {
            this.isDialogVisible = false
            this.card_index = ''
            this.pageNum = 1
            this.total = 0
        },
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    display: flex;
    justify-content: center;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
/deep/ .el-dialog__body {
    padding: 14px 20px 14px;
}
/deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .el-radio-button {
        .el-radio-button__inner {
            border-left: 1px solid #DCDFE6;
            height: 292px;
            width: 252px;
            border-radius: 6px;
            padding: 0;
            margin: 0 10px 16px;
            background: #F5F6FD;
            box-shadow: 0px 6px 12px rgba(85, 87, 105, 0.16);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .card_box_1 {
              width: 200px;
              height: 236px;
              padding: 0 12px 14px;
              background: #fff;
              border-radius: 6px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 22px;
              color: #252525;
              .headerImg {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: #f5f8ff;
                border: 1px solid #252525;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: -24px;
                > img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              > h3 {
                margin: 4px 0;
              }
              .qrCodeImg {
                width: 80px;
                height: 80px;
                overflow: hidden;
                background: #f5f8ff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px 0 14px 0;
                > img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              > p {
                align-self: flex-start;
                font-size: 12px;
                margin-bottom: 10px;
              }
              .iphone {
                align-self: flex-start;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                img {
                  width: 24px;
                }
              }
            }
            .card_box_2 {
              width: 228px;
              height: 120px;
              position: relative;
              background: #4046E6;
              border-radius: 6px;
              padding: 12px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              color: #fff;
              .headerImg {
                position: absolute;
                top: 12px;
                right: 12px;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: #f5f8ff;
                border: 1px solid #252525;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                > img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              > p {
                margin-top: 10px;
                font-size: 12px;
              }
              .iphone {
                align-self: flex-start;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                img {
                  width: 24px;
                }
              }
            }
            &:hover {
                border-color: #FF7500;
                background: #FFF2E6;
            }
        }
        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            border-color: #FF7500;
            background: #FFF2E6;
        }
    }
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 12px;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    color: #FF7500;
    cursor: pointer;
  }
}
</style>