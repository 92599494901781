<template>
  <el-dialog
    title="添加投票"
    :visible.sync="isVisible"
    width="1110px"
    top="10vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="voteBeforeClose"
  >
    <div class="branch_videos_holder">
        <el-radio-group v-model="voteId">
            <el-radio-button :label="item.id" v-for="item in voteList" :key="item.id">
                <div class="vote_box">
                  <h3>{{ item.title }}</h3>
                  <div class="v_info">
                    <span>0人参与投票</span>
                    <span>投票时间：{{ item.voteStartDate }} ~ {{ item.voteEndDate }}</span>
                  </div>
                  <div class="v_option" v-for="(value, index) in Object.keys(item.optionArray)" :key="index">
                    <div class="option_row">
                        <b>{{ value }}</b>
                        <span>{{ item.optionArray[value] }}票 {{ getProportion(item.optionArray[value], item.optionArray) }}</span>
                    </div>
                    <div class="option_bar">
                        <span :style="{ width: getProportion(item.optionArray[value], item.optionArray) }"> </span>
                    </div>
                  </div>
                </div>
            </el-radio-button>
        </el-radio-group>
        <div class="pagination" v-show="total > pageSize">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <span @click="openUploadModal">新增投票</span>
      <div>
        <el-button @click="voteBeforeClose">取 消</el-button>
        <el-button @click="voteConfirm" type="primary">确 定</el-button>
      </div>
    </span>
    <EditVote ref="editVote" @getVoteList="getVoteList" />
  </el-dialog>
</template>

<script>
import { toPercent } from '@/utils/utils'
import EditVote from './editVote.vue'
export default {
  name: "add-vote",
  data() {
    return {
        loading: false,
        pageSize: 4,
        pageNum: 1,
        total: 0,
        voteList: [],
        isVisible: false,
        voteId: undefined,
    };
  },
  components: {
    EditVote
  },
  methods: {
    // 投标占比
    getProportion(value, dataSource) {
        if (dataSource instanceof Object && Object.keys(dataSource).length > 0) {
            const numArr = Object.keys(dataSource).map((item) => {
                return dataSource[item]
            })
            const reducer = (previousValue, currentValue) => previousValue + currentValue;
            const total = numArr.reduce(reducer)
            return toPercent(total > 0 ? value/total : 0, 0)
        }
    },
    // 上传图片
    openUploadModal() {
      this.$refs.editVote.show()
    },
    show() {
      this.isVisible = true;
      if (this.voteList.length == 0) {
        this.getVoteList();
      }
    },
    //图片弹框关闭
    voteBeforeClose() {
      this.isVisible = false;
      this.voteId = undefined;
    },
    //图片弹框确认
    async voteConfirm() {
      this.$emit('onVoteChoose', this.voteList.filter(item => item.id === this.voteId)[0])
      this.voteBeforeClose();
    },
    //获取列表
    async getVoteList() {
        this.loading = true
        const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
        };
        try {
            const data = await this.API.materialCenter.voteInfoList(params);
            this.voteList = data.data.list.map((item) => {
                return {
                  ...item,
                  optionArray: JSON.parse(item.optionArray)
                }
              });
            this.total = data.data.total;
            this.loading = false
        } catch (error) {
            this.loading = false
        }
    },
    // 分页
    handleCurrentChange(val) {
        this.pageNum = val
        this.getVoteList()
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    display: flex;
    justify-content: center;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
/deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .el-radio-button {
        margin-right: 14px;
        margin-bottom: 14px;
        .el-radio-button__inner {
            border-left: 1px solid #DCDFE6;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: content-box;
            box-shadow: unset;
            background: #F7F8FA;
            text-align: left;
            .vote_box {
              padding: 12px 12px;
              height: 236px;
              width: 518px;
              overflow: hidden;
              > h3 {
                margin-bottom: 12px;
                color: #1A1A34;
                font-size: 16px;
              }
              .v_info {
                font-size      : 12px;
                color          : #8D8DA7;
                margin-bottom  : 16px;
                > span {
                  margin-right: 32px;
                }
              }
              .v_option {
                margin-bottom: 10px;
                .option_row {
                  display        : flex;
                  justify-content: space-between;
                  margin-bottom  : 5px;
                  align-items: center;
                  b {
                    font-weight: normal;
                    flex: 1;
                    color: #1A1A34;
                  }
                  span {
                    color    : #8D8DA7;
                    font-size: 12px;
                    width: 22%;
                    text-align: right;
                  }
                }
                .option_bar {
                  height    : 12px;
                  background: #E2E2EA;
                  span {
                    height        : 12px;
                    display       : inline-block;
                    vertical-align: top;
                    width         : 0;
                    background    : #FEA644;
                  }
                }
              }
            }
            &:hover {
                border-color: #FF7500;
                background: #FFF2E6;
            }
        }
        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            border-color: #FF7500;
            background: #FFF2E6;
        }
    }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    color: #FF7500;
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
</style>