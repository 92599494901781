<template>
  <div class="edit_holder">
    <div class="navi_back"><span @click="back">返回</span></div>
    <div class="video_des">
      <span>描述：</span>
      <el-input placeholder="请输入描述" v-model="editVideoOptions.video.sDes" maxlength="60" show-word-limit></el-input>
    </div>
    <div class="edit_row">
      <div class="edit_col1">
        <div class="video_preview">
          <video-player :options="editVideoOptions" playIn="pc" ref="refVideoPlayer" @singleTap="singleTap" />
        </div>
        <el-button type="default" @click="previewVideo">预览</el-button>
        <el-button type="primary" @click="saveVideo">保存</el-button>
        <el-button type="success" :disabled="isPublish" @click="publishVideo">{{
          isPublish ? "已发布" : "发布"
        }}</el-button>
      </div>
      <div class="edit_col2">
        <div class="top_row">
          <div class="card_setting">
            <el-button @click="selectCard" v-if="!editVideoOptions.cardSettings.isShowCard" type="primary">添加名片</el-button>
            <div v-else class="card">
              <span>{{ `${editVideoOptions.cardSettings.name}的名片`}}</span>
              <i class="el-icon-close" @click="closeCard"></i>
            </div>
          </div>
        </div>
        <ul class="timeline_list">
          <li v-for="(item, index) in editVideoTimeline" :key="index">
            <div class="t_time">
              <div class="tt1">
                <span>{{ item.startTimeTxt }}</span> ~ <span>{{ item.endTimeTxt }}</span>
              </div>
              <div class="tt2">
                <span>{{ item.from.toFixed(1) }}</span> ~ <span>{{ item.to.toFixed(1) }}</span>
              </div>
            </div>
            <popover-text
              :value="item.content"
              :editIndex="index"
              :addGoods="addGoods"
              :addTools="addTools"
              :addMarket="addMarket"
              :addAdvice="addAdvice"
              :addAccount="addAccount"
            />
            <div class="t_elements">
              <draggable
                tag="ul"
                v-model="item.elements"
                :class="{ ss_draggable_list_APP: true }"
                v-bind="dragOptions"
                v-if="item.elements.length > 0"
              >
                <li
                  v-for="(cell, cellIndex) in item.elements"
                  :key="cellIndex"
                  :class="setClass(cell.type)"
                >
                  <!-- 工具、行情、资讯在时间轴上的显示，跳转到的是灯塔app页 -->
                  <template v-if="cell.type == 'appJump'">
                    <span class="text">
                      {{ cell.title }}
                    </span>
                    <i class="el-icon-close" @click="removeAt(index, cellIndex)"></i>
                  </template>
                  <!-- 外链、商品、开户在时间轴上的显示，跳转到的是第三方页面 -->
                  <template v-if="cell.type === 'jumpUrl'">
                    <img :src="cell.image" alt="" />
                    <div>
                      <span>{{ cell.title }}</span>
                      <span>{{ cell.desc }}</span>
                    </div>
                    <i class="el-icon-close" @click="removeAt(index, cellIndex)"></i>
                  </template>
                  <!-- 图片在时间轴上的显示 -->
                  <template v-if="cell.type === 'image'">
                    <div class="image_img"></div>
                    <div class="image_txt">{{ cell.title }}</div>
                    <i class="el-icon-close" @click="removeAt(index, cellIndex)"></i>
                  </template>
                  <!-- 投票、报名在时间轴上的显示 -->
                  <template v-if="['vote', 'signup', 'branchVideos', 'branchVideo'].indexOf(cell.type) !== -1">
                    <div class="txt">{{ cell.title }}</div>
                    <i class="el-icon-close" @click="removeAt(index, cellIndex)"></i>
                  </template>
                </li>
              </draggable>
              <el-popover placement="left" width="180" trigger="hover">
                <ul class="add_list">
                  <li @click="addGoods(index)"><i class="el-icon-shopping-cart-2"></i>商品</li>
                  <li @click="addTools(index)"><i class="el-icon-setting"></i>工具</li>
                  <li @click="addMarket(index)"><i class="el-icon-data-line"></i>行情</li>
                  <li @click="addAdvice(index)"><i class="el-icon-news"></i>资讯</li>
                  <li @click="addAccount(index)"><i class="el-icon-bank-card"></i>开户</li>
                  <li @click="addJumpUrl(index)"><i class="el-icon-link"></i>外链</li>
                  <li @click="addImage(index)"><i class="el-icon-picture-outline"></i>图片</li>
                  <li @click="addVote(index)"><i class="el-icon-thumb"></i>投票</li>
                  <li @click="addSignup(index)"><i class="el-icon-document-add"></i>报名</li>
                  <li @click="addBranchVideo(index)"><i class="el-icon-video-camera"></i>分支视频</li>
                </ul>
                <i slot="reference" class="el-icon-circle-plus btn_add_elements"></i>
              </el-popover>
              <div class="elements_duration" v-if="item.elements.length > 0">
                <label class="duration_label">第</label>
                <input
                  :ref="`refInputFrom${index}`"
                  @keydown.enter="inputFromBlur(index)"
                  type="number"
                  :min="item.from.toFixed(1)"
                  :max="item.to.toFixed(1) - 0.1"
                  step="0.1"
                  class="duration_input"
                  v-model="item.adjustFrom"
                />
                <label class="duration_label">秒出现</label>
                <label v-if="item.elements[0].type == 'branchVideos'" class="duration_label">，阻断播放</label>
                <span v-if="item.elements[0].type != 'branchVideos'">
                  <label class="duration_label">，持续</label>
                  <input
                    :ref="`refInputDuration${index}`"
                    @keydown.enter="inputDurationBlur(index)"
                    type="number"
                    min="5"
                    max="888"
                    step="1"
                    class="duration_input seconds_input"
                    v-model="item.duration"
                  />
                  <label class="duration_label">秒</label>
                </span>
              </div>
            </div>
            <div class="t_preview" v-if="item.elements.length > 0">
              <el-button type="default" @click="previewVideo(index)">预览</el-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <add-jump-url ref="refAddJumpUrl" @onJumpUrlChoose="onJumpUrlChoose"></add-jump-url>
    <AddGoods ref="addGood" @onGoodChoose="onGoodChoose" />
    <AddTools ref="addTool" @onToolChoose="onToolChoose" />
    <AddMarkets ref="addMarket" @onMarketChoose="onMarketChoose" />
    <AddAdvice ref="addAdvice" @onAdviceChoose="onAdviceChoose" />
    <AddAccount ref="addAccount" @onAccountChoose="onAccountChoose" />
    <AddVote ref="addVote" @onVoteChoose="onVoteChoose" />
    <AddSignup ref="addSignup" @onSignupChoose="onSignupChoose" />
    <AddBusinessCard ref="addBusinessCard" @onBusinessCardChoose="onBusinessCardChoose" />
    <AddImage ref="refAddImage" @onImageChoose="onImageChoose" />
    <AddBranchVideo ref="addBranchVideo" @onBranchVideoChoose="onBranchVideoChoose" />
  </div>
</template>

<script>
import { sleep, arrRemove } from "@/utils/utils";
import VideoPlayer from "@/components/videoPlayer/VideoPlayer";
import PopoverText from "./PopoverText.vue"; // 转换时间段文字
import AddJumpUrl from "../components/AddJumpUrl/index.vue"; // 外链
import AddGoods from "../components/AddGoods/index.vue"; // 商品
import AddTools from "../components/AddTools/index.vue"; // 工具
import AddMarkets from "../components/AddMarkets/index.vue"; // 行情
import AddAdvice from "../components/AddAdvices/index.vue"; // 资讯
import AddAccount from "../components/AddAccount/index.vue"; // 账号
import AddVote from "../components/AddVote/index.vue" // 投票
import AddSignup from "../components/AddSignup/index.vue" // 报名
import AddBusinessCard from '../components/AddBusinessCard/index.vue' // 名片
import AddImage from '../components/AddImage/index.vue' // 图片
import AddBranchVideo from '../components/AddBranchVideo/index.vue'
import draggable from "vuedraggable";
export default {
  name: "InteractVideo-List",
  data() {
    // this.LOGIN_METHOD = window.LOGIN_METHOD;
    return {
      isProgressVisible: false,
      uploadProgress: 0,
      isGuideVisible: false,
      fullTypes: ["branchVideos", "iframe-news", "iframe-k", "image", "vote", "signup", "jumpUrl", "appJump"],
      editingIndex: 0, //时间轴里正在插入元素的索引
      editingCellIndex: 0, //时间轴里正在插入元素的索引的元素索引
      editVideoId: "", //当前编辑的互动视频id
      isPublish: false, // 当前视频是否发布 false|未发布 true|发布
      editVideoOptions: {
        width: 360,
        height: 640,
        author: {
          avatar:
            JSON.parse(localStorage.getItem("loginInfo")).data.stAccountInfo.sFaceUrl ||
            "https://res.idtcdn.com/beacon/images/photo_default.png",
          name: JSON.parse(localStorage.getItem("loginInfo")).data.stAccountInfo.sUserName || "",
          authorId: JSON.parse(localStorage.getItem("loginInfo")).data.stAccountInfo.iAccountId || "", // 用户ID
        },
        // 视频信息
        video: {
          poster: "",
          src: "",
          iLikeNum: 0,
          iShareNum: 0,
          sTitle: "",
          sDes: "", // 视频描述
          smallPicUrl: "",
        },
        // 素材
        branches: [],
        // 名片
        cardSettings: {
          isShowCard: false, //是否展示名片
          id: '',
          name: "",
          title: "",
          corpName: "",
          corpLogo: "",
          mobile: "",
          code: "",
        },
      },
      // 时间轴
      editVideoTimeline: [],
    };
  },
  components: {
    draggable,
    VideoPlayer,
    PopoverText,
    AddJumpUrl,
    AddGoods,
    AddTools,
    AddMarkets,
    AddAdvice,
    AddAccount,
    AddVote,
    AddSignup,
    AddBusinessCard,
    AddImage,
    AddBranchVideo
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        ghostClass: "ghost",
      };
    },
  },
  created() {
    const { videoItem } = this.$route.query;
    if (videoItem) {
      try {
        let item = JSON.parse(videoItem);
        this.edit(item);
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    // 设置选择素材后的类型
    setClass(type) {
      switch (type) {
        case "jumpUrl":
          return 'good_li'
          break;
        case "appJump":
          return 'url_li'
          break;
        case "image":
          return 'image_li'
          break;
        default:
          return 'text_li'
          break;
      }
    },
    singleTap() {
      this.$refs.refVideoPlayer.togglePlayer();
    },
    //名片编辑
    selectCard() {
       this.$refs.addBusinessCard.show();
    },
    // 设置名片信息cardSettings
    onBusinessCardChoose(data) {
      if (data && data instanceof Object) {
        this.editVideoOptions = Object.assign({}, this.editVideoOptions, {
          cardSettings: {
            isShowCard: true,
            id: data.id,
            name: data.name,
            title: data.position,
            corpName: data.company,
            corpLogo: data.headUrl,
            mobile: data.telephone,
            code: data.wxQrCode
          },
        });
      }
    },
    // 清空名片信息
    closeCard() {
      this.editVideoOptions = Object.assign({}, this.editVideoOptions, {
          cardSettings: {
            isShowCard: false, //是否展示名片
            id: '',
            name: "",
            title: "",
            corpName: "",
            corpLogo: "",
            mobile: "",
            code: "",
          },
        });
    },
    //视频编辑-返回
    back() {
      this.$refs.refVideoPlayer.pause();
      this.$refs.refVideoPlayer.reset();
      this.$router.push({
        path: "/interact_video/list",
      });
    },
    /*预览视频
      这里的bug是，如果videoOption改变后，需要等待一会让dom生效，goto才能生效
    */
    async previewVideo(previewIndex) {
      this.updateEditVideoOptions();
      let gotoSeconds = 0;
      if (!isNaN(previewIndex)) {
        gotoSeconds = this.editVideoTimeline[previewIndex].from;
      }
      await sleep(500);
      this.$refs.refVideoPlayer.goto(gotoSeconds);
      this.$refs.refVideoPlayer.play();
    },

    // 保存视频
    saveVideo(tool = true) {
      const _that = this;
      return new Promise(async (resolve) => {
          _that.updateEditVideoOptions();
          const params = {
            id: _that.editVideoId,
            updateContent: JSON.stringify(_that.editVideoOptions),
          };
          const data = await _that.API.interactVideo.videoUpdate(params);
          // 控制是否给出保存提示
          if (tool) {
            if (data.code == 200) {
              _that.$message.success("保存成功");
            } else {
              _that.$message.error("删除失败" + JSON.stringify(data));
            }
          }
          resolve(data);
      });
    },
    // 发布视频
    async publishVideo() {
      const bool = await this.saveVideo(false);
      if (bool) {
        const res = await this.API.interactVideo.publishVideo({
          id: this.editVideoId,
        });
        if (res && res instanceof Object && res.code === 200) {
          this.$message.success("发布成功！");
          this.isPublish = true;
        } else {
          this.$message.error("发布失败！");
        }
      }
    },
    //手机timeline里的elements，形成存储配置editVideoOptions，也是播放时用到的配置。ps：视频元素显示目前提供两种，第一种是分支视频，第二种是不限数量的悬浮到视频上
    updateEditVideoOptions() {
      let branches = [];
      this.editVideoTimeline.map((item) => {
        if (item.elements.length > 0) {
           if (item.elements[0].type == "branchVideos") {
            branches.push({
              stopTime: item.adjustFrom,
              interactType: "stopBtns",
              stopBtnsSettings: this.getStopBtnsSettings(item.elements),
            });
          } else if (["iframe-news", "iframe-k", "image", "jumpUrl", "appJump", "vote", "signup", "branchVideo"].indexOf(item.elements[0].type) > -1) {
            branches.push({
              stopTime: item.adjustFrom,
              interactType: "mix",
              mixSettings: {
                duration: item.duration,
                nodes: this.getMixNodes(item.elements),
              },
            });
          }
        }
      });
      this.editVideoOptions = Object.assign({}, this.editVideoOptions, {
        branches: branches,
      });
    },
    getStopBtnsSettings(elements) {
      let stopBtnsSettings = [];
      elements.map((item) => {
        stopBtnsSettings.push({
          label: item.title,
          src: item.url,
        });
      });
      return stopBtnsSettings;
    },
    // 视频预览|保存元素配置
    getMixNodes(elements) {
      let mixSettingsNodes = [];
      elements.map((item) => {
        if (["iframe-news", "iframe-k"].indexOf(item.type) > -1) {
          mixSettingsNodes.push({
            mixType: "iframe",
            class: item.type,
            title: item.title,
            url: item.url,
          });
        }
        if (["image"].indexOf(item.type) > -1) {
          mixSettingsNodes.push({
            mixType: "image",
            title: item.title,
            url: item.url,
          });
        }
        if (["jumpUrl"].indexOf(item.type) > -1) {
          mixSettingsNodes.push({
            mixType: "jumpUrl",
            title: item.title,
            image: item.image,
            desc: item.desc,
            url: item.url,
            id: item.id || '', // 外链没有id，默认为空字符串
            realType: item.realType,
          });
        }
        if (["appJump"].indexOf(item.type) > -1) {
          mixSettingsNodes.push({
            mixType: "appJump",
            title: item.title,
            url: item.url,
            id: item.id,
            realType: item.realType,
          });
        }
        if (["vote"].indexOf(item.type) > -1) {
           mixSettingsNodes.push({
            mixType: "vote",
            title: item.title,
            id: item.id,
            option: item.option,
            singleSelected: item.singleSelected,
            activityDate: item.activityDate,
          });
        }
        if (["signup"].indexOf(item.type) > -1) {
           mixSettingsNodes.push({
            mixType: "signup",
            activityDate: item.activityDate,
            buttonText: item.buttonText,
            title: item.title,
            nameStatus: item.nameStatus,
            id: item.id,
          });
        }
        if (["branchVideo"].indexOf(item.type) > -1) {
           mixSettingsNodes.push({
            mixType: "branchVideo",
            title: item.title,
            url: item.url,
          });
        }
      });
      return mixSettingsNodes;
    },
    //元素删除
    removeAt(index, cellIndex) {
      this.editingIndex = index;
      this.editVideoTimeline[index].elements.splice(cellIndex, 1);
    },
    inputBlur(index, cellIndex) {
      this.editingIndex = index;
      this.$refs[`refInputIndex${index}CellIndex${cellIndex}`][0].blur();
    },
    inputDurationBlur(index) {
      this.editingIndex = index;
      this.$refs[`refInputDuration${index}`][0].blur();
    },
    inputFromBlur(index) {
      this.editingIndex = index;
      this.$refs[`inputFromBlur${index}`][0].blur();
    },
    //判断是否能找到某个类别
    async isFound(nameArr) {
      let isFound = false;
      return new Promise((resolve, reject) => {
        const elements = this.editVideoTimeline[this.editingIndex].elements;
        if (elements.length > 0) {
          elements.map((item) => {
            if (nameArr.indexOf(item.type) > -1) {
              isFound = true;
            }
          });
        }
        resolve(isFound);
      });
    },
    // 判断某个类别在视频元素中是否存在
    async isType(type) {
      let isTrue = false
      return new Promise((resolve) => {
        let elements = []
        this.editVideoTimeline.forEach((item) => {
          if (item.elements && Array.isArray(item.elements) && item.elements.length > 0) {
            elements = elements.concat(item.elements)
          }
        })
        if (elements.length > 0) {
          elements.forEach((item) => {
            if (item.type === type) {
              isTrue = true
            }
          })
        }
        resolve(isTrue);
      })
    },
    //判断是否到达最大数
    isReachMax(index) {
      return new Promise((resolve, reject) => {
        if (this.editVideoTimeline[index].elements.length >= 3) {
          this.$message.error("最多加入3个互动元素！");
          reject();
        } else {
          resolve();
        }
      });
    },
    // 判断一段视频设置的分支视频数
    isBranchNum(index) {
      return new Promise((resolve, reject) => {
        if (this.editVideoTimeline[index].elements.filter(item => item.type === 'branchVideo').length >= 3) {
          this.$message.error("最多加入3个分支视频！");
          resolve(false);
        } else {
          resolve(true);
        }
      });
    },
    //添加分支视频
    async addBranchVideo(index) {
      const bool = await this.isBranchNum(index)
      if (bool) {
        this.editingIndex = index;
        this.editingCellIndex = this.editVideoTimeline[index].elements.length;
        this.$refs.addBranchVideo.show();
      }
    },
    //分支视频选择确认
    onBranchVideoChoose(data) {
      this.editVideoTimeline[this.editingIndex].elements.splice(this.editingCellIndex, 1, {
        type: "branchVideo",
        url: data.choosedBranchVideoUrl,
        title: data.choosedBranchTitle,
      });
      // this.editVideoTimeline[this.editingIndex].elements.splice(this.editingCellIndex, 1, {
      //   type: "branchVideos",
      //   url: data.choosedBranchVideoUrl,
      //   title: data.choosedBranchTitle,
      // });
    },
    //添加内嵌链接iframe
    async addIframe(index) {
      await this.isReachMax(index);
      this.editingIndex = index;
      const isCan1 = await this.isFound(["branchVideos"]);
      const isCan2 = await this.isFound("vote");
      const isCan3 = await this.isFound("signup");
      if (isCan1) {
        this.$message.error("内嵌链接不能与分支视频共同存在！");
        return;
      }
      if (isCan2) {
        this.$message.error("内嵌链接不能与投票共同存在！");
        return;
      }
      if (isCan3) {
        this.$message.error("内嵌链接不能与报名共同存在！");
        return;
      }
      this.editingCellIndex = this.editVideoTimeline[index].elements.length;
      this.$refs.refAddIframe.show();
    },
    //内嵌链接选择确认
    onIframeChoose(data) {
      this.editVideoTimeline[this.editingIndex].elements.splice(this.editingCellIndex, 1, {
        url: data.url,
        title: data.title,
        type: data.type,
      });
    },
    //添加外跳链接
    async addJumpUrl(index) {
      // await this.isReachMax(index);
      this.editingIndex = index;
      const isCan1 = await this.isFound("branchVideos");
      const isCan2 = await this.isFound(["vote"]);
      const isCan3 = await this.isFound(["signup"]);
      if (isCan1) {
        this.$message.error("外跳链接不能与分支视频共同存在！");
        return;
      }
      if (isCan2) {
        this.$message.error("外跳链接不能与投票共同存在！");
        return;
      }
      if (isCan3) {
        this.$message.error("外跳链接不能与报名共同存在！");
        return;
      }
      this.editingCellIndex = this.editVideoTimeline[index].elements.length;
      this.$refs.refAddJumpUrl.show();
    },
    //外跳链接选择确认
    onJumpUrlChoose(data) {
      this.editVideoTimeline[this.editingIndex].elements.splice(this.editingCellIndex, 1, {
        url: data.url,
        title: data.title,
        image: data.image,
        desc: data.desc,
        realType: 'jumpUrl',
        type: "jumpUrl",
      });
    },
    //添加图片
    async addImage(index) {
      this.editingIndex = index;
      this.$refs.refAddImage.show();
    },
    //图片选择确认
    onImageChoose(data) {
      this.editVideoTimeline[this.editingIndex].elements.push({
        type: "image",
        url: data.picUrl,
        title: data.picName,
      });
    },
    //添加投票
    async addVote(index) {
      const isTrue = await this.isType('vote')
      if (isTrue) {
        this.$message.error("只能添加一次投票！");
        return;
      }
      this.editingIndex = index;
      this.$refs.addVote.show();
    },
    //投票选好了
    onVoteChoose(data) {
      this.editVideoTimeline[this.editingIndex].elements.push({
        type: "vote",
        id: data.id,
        title: data.title,
        option: data.optionArray,
        activityDate: data.voteEndDate,
        singleSelected: data.singleSelected, // 0|单选 1|多选
      });
    },
    //添加报名
    async addSignup(index) {
      const isTrue = await this.isType('signup')
      if (isTrue) {
        this.$message.error("只能添加一次报名！");
        return;
      }
      this.editingIndex = index;
      this.$refs.addSignup.show();
    },
    //报名选好了
    onSignupChoose(data) {
      this.editVideoTimeline[this.editingIndex].elements.push({
        type: "signup",
        id: data.id,
        title: data.title,
        nameStatus: data.nameStatus,
        activityDate: data.endDate,
        buttonText: data.buttonText,
      });
    },
    // 添加商品
    async addGoods(index) {
      this.editingIndex = index;
      this.$refs.addGood.show();
      this.editingCellIndex = this.editVideoTimeline[index].elements.length;
    },
    onGoodChoose(data) {
      this.editVideoTimeline[this.editingIndex].elements.splice(this.editingCellIndex, 1, {
        url: data.goodInfo.sBuyUrl,
        title: data.form.good_name,
        image: data.goodInfo.sIcon,
        desc: data.form.good_des,
        id: data.goodInfo.iType,
        realType: 'good',
        type: "jumpUrl",
      });
    },
    // 添加工具
    async addTools(index, value) {
      this.editingIndex = index;
      this.$refs.addTool.show(value);
      this.editingCellIndex = this.editVideoTimeline[index].elements.length;
    },
    onToolChoose(data) {
      const url = this.getToolUrl(data);
      this.editVideoTimeline[this.editingIndex].elements.splice(this.editingCellIndex, 1, {
        url: url,
        title: data.form.stock_des,
        id: data.form.tool_index,
        realType: 'tool',
        type: "appJump",
      });
    },
    //获取url
    getToolUrl(data) {
      let url;
      const seccode = data.form.stock_code;
      const secname = data.form.stock_des.split("-")[0];
      if (data.toolInfo.addrUrl.indexOf("chartFormSelect") > -1) {
        var bodyJson = {
          secCode: seccode,
          secName: secname,
        };
        url = "beacon://chartFormSelect?body=" + encodeURIComponent(JSON.stringify(bodyJson));
      } else {
        url = data.toolInfo.addrUrl.replace(/replaceseccode/i, seccode).replace(/replacesecname/i, secname);
      }
      return url;
    },
    // 添加行情
    async addMarket(index, value) {
      this.editingIndex = index;
      this.$refs.addMarket.show(value);
      this.editingCellIndex = this.editVideoTimeline[index].elements.length;
    },
    onMarketChoose(data) {
      this.editVideoTimeline[this.editingIndex].elements.splice(this.editingCellIndex, 1, {
        // url: `https://sec.wedengta.com/stockDetail.html?secCode=${data.stock_code}&dt_from=web`,
        url: `beacon://stock?id=${data.form.stock_code}&name=${data.marketInfo.label}`,
        title: data.form.market_name,
        id: data.form.stock_code,
        realType: 'market',
        type: "appJump",
      });
    },
    // 添加资讯
    async addAdvice(index, value) {
      this.editingIndex = index;
      this.$refs.addAdvice.show(value);
      this.editingCellIndex = this.editVideoTimeline[index].elements.length;
    },
    // 添加咨询
    onAdviceChoose(data) {
      this.editVideoTimeline[this.editingIndex].elements.splice(this.editingCellIndex, 1, {
        url: `${data.adviceInfo.sDtInfoUrl}&dt_page_type=11&webviewType=userActivitesType`,
        title: data.form.advice_des,
        id: `${data.form.stock_code}_${data.form.advice_id}`, // 股票id+资讯id
        realType: 'advice',
        type: "appJump",
      });
    },
    // 添加开户
    async addAccount(index, value) {
      this.editingIndex = index;
      this.$refs.addAccount.show(value);
      this.editingCellIndex = this.editVideoTimeline[index].elements.length;
    },
    onAccountChoose(data) {
      this.editVideoTimeline[this.editingIndex].elements.splice(this.editingCellIndex, 1, {
        url: data.accountInfo.sUrl,
        title: data.form.account_name,
        image: data.accountInfo.sIcon,
        desc: data.form.account_des,
        id: data.accountInfo.id,
        realType: 'account',
        type: "jumpUrl",
      });
    },
    //获取文章内容
    async getVideoDetail(id) {
      return new Promise(async (resolve, reject) => {
        const params = {
          id: id,
        };
        const { data } = await this.API.interactVideo.videoDetail(params);
        resolve(data);
      });
    },
    // 根据视频时长，已10秒为间距分段
    changeVideoDuration(value) {
      const num = Math.ceil(value / 10);
      let arr = [];
      for (let i = 1; i <= num; i++) {
        arr.push({
          from: 0,
          to: 10 * i < value ? 10 * i : value,
          content: "",
        });
      }
      return arr;
    },
    //视频列表-编辑
    async edit(item) {
      if (item.jobStatus != "Success") {
        return;
      }
      const data = await this.getVideoDetail(item.id);
      this.editVideoId = data.id;
      this.editVideoTimeline = JSON.parse(data.mediaContent) || this.changeVideoDuration(data.videoDuration);
      this.isPublish = data.publishStatus ? true : false;
      let updateContent = {
        video: {
          iId: item.id, // 添加id，用于埋点
          poster: data.picUrl || "",
          src: data.videoUrl || "",
          smallPicUrl: data.smallPicUrl || "",
          iLikeNum: data.likedNum || 0,
          iShareNum: data.shareNum || 0,
          sTitle: data.title || "",
          sDes: "",
        },
        branches: [],
      };
      if (data.updateContent) {
        updateContent = {
          ...JSON.parse(data.updateContent),
          video: {
            ...JSON.parse(data.updateContent).video,
            // 点赞数、转发数、视频标题会被其他接口改变，编辑的时候更新updateContent
            iLikeNum: data.likedNum || 0,
            iShareNum: data.shareNum || 0,
            sTitle: data.title || "",
          },
          author: {
            avatar:
              JSON.parse(localStorage.getItem("loginInfo")).data.stAccountInfo.sFaceUrl ||
              "https://res.idtcdn.com/beacon/images/photo_default.png",
            name: JSON.parse(localStorage.getItem("loginInfo")).data.stAccountInfo.sUserName || "",
            authorId: JSON.parse(localStorage.getItem("loginInfo")).data.stAccountInfo.iAccountId || "", // 用户ID
          },
        };
      }
      // 时间段
      if (this.editVideoTimeline.length > 0) {
        this.editVideoTimeline.map((item) => {
          this.$set(item, "startTimeTxt", this.s_to_hs(item.from));
          this.$set(item, "endTimeTxt", this.s_to_hs(item.to));
          this.$set(item, "elements", this.getElements(updateContent.branches, item));
          this.$set(item, "duration", this.getDuration(updateContent.branches, item));
          this.$set(item, "adjustFrom", this.getAdjustFrom(updateContent.branches, item));
          return item;
        });
      }
      this.editVideoOptions = Object.assign({}, this.editVideoOptions, {
        ...updateContent,
      });
    },
    //获取元素的调整过的出现点
    getAdjustFrom(branches, timelineItem) {
      let adjustFrom = timelineItem.from.toFixed(1);
      branches.map((item) => {
        if (timelineItem.from.toFixed(1) <= item.stopTime && item.stopTime < timelineItem.to.toFixed(1)) {
          adjustFrom = item.stopTime;
        }
      });
      return adjustFrom;
    },
    //获取元素的持续时长
    getDuration(branches, timelineItem) {
      let duration = 5;
      branches.map((item) => {
        if (timelineItem.from.toFixed(1) <= item.stopTime && item.stopTime < timelineItem.to.toFixed(1)) {
          duration = item[item.interactType + "Settings"].duration;
        }
      });
      return duration;
    },
    //拉到数据后，处理成互动元素展示
    getElements(branches, timelineItem) {
      let elements = [];
      if (branches.length == 0) {
        return elements;
      }
      branches.map((item) => {
        if (
          Number(timelineItem.from.toFixed(1)) <= Number(item.stopTime) &&
          Number(item.stopTime) < Number(timelineItem.to.toFixed(1))
        ) {
          if (item.interactType == "mix") {
            //mix里包含iframe-news，iframe-k，image，jumpUrl
            item[item.interactType + "Settings"].nodes.map((cell) => {
              if (cell.mixType == "iframe") {
                elements.push({
                  type: cell.class,
                  title: cell.title,
                  url: cell.url,
                });
              } else if (cell.mixType == "image") {
                elements.push({
                  type: cell.mixType,
                  title: cell.title,
                  url: cell.url,
                });
              } else if (cell.mixType == "jumpUrl") {
                elements.push({
                  type: cell.mixType,
                  title: cell.title,
                  desc: cell.desc,
                  url: cell.url,
                  image: cell.image,
                  id: cell.id || '',
                  realType: cell.realType,
                });
              } else if (cell.mixType == "appJump") {
                elements.push({
                  type: cell.mixType,
                  title: cell.title,
                  url: cell.url,
                  id: cell.id,
                  realType: cell.realType,
                });
              } else if (cell.mixType == "vote") {
                elements.push({
                  type: cell.mixType,
                  id: cell.id,
                  title: cell.title,
                  option: cell.option,
                  singleSelected: cell.singleSelected,
                  activityDate: cell.activityDate,
                })
              } else if (cell.mixType == "signup") {
                elements.push({
                  type: cell.mixType,
                  activityDate: cell.activityDate,
                  buttonText: cell.buttonText,
                  title: cell.title,
                  nameStatus: cell.nameStatus,
                  id: cell.id,
                })
              } else if (cell.mixType == 'branchVideo') {
                elements.push({
                  type: cell.mixType,
                  title: cell.title,
                  url: cell.url,
                })
              }
            });
          } else if (item.interactType == "stopBtns") {
            item["stopBtnsSettings"].map((cell) => {
              elements.push({
                type: "branchVideo",
                title: cell.label,
                url: cell.src,
              });
            });
          }
        }
      });
      return elements;
    },
    //把秒转成 00:10.2 这样的分秒形式
    s_to_hs(s) {
      var h;
      h = Math.floor(s / 60);
      s = (s % 60).toFixed(1);
      h += "";
      s += "";
      h = h.length == 1 ? "0" + h : h;
      s = parseInt(s) < 10 ? "0" + s : s;
      return h + ":" + s;
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
