<template>
  <el-dialog
    title="添加图片"
    :visible.sync="isVisible"
    width="1110px"
    top="10vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="imageBeforeClose"
  >
    <div class="branch_videos_holder">
        <el-radio-group v-model="choosedImageUrl">
            <el-radio-button :label="item.picUrl" v-for="(item, index) in imageList" :key="index">
                <div class="v_img">
                  <img :src="item.picUrl" alt="">
                </div>
                <!-- <span :title="item.picName">{{ item.picName }}</span> -->
            </el-radio-button>
        </el-radio-group>
        <div class="pagination" v-show="total > pageSize">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <span @click="openUploadModal" v-if="userInfo.remainTime > 0 || !userInfo.whiteStatus">新增图片</span>
      <span v-else></span>
      <div>
        <el-button @click="imageBeforeClose">取 消</el-button>
        <el-button @click="imageConfirm" type="primary">确 定</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "add-image",
  data() {
    return {
        loading: false,
        pageSize: 10,
        pageNum: 1,
        total: 0,
        imageList: [],
        isVisible: false,
        choosedImageUrl: "",
        userInfo: JSON.parse(localStorage.getItem('userInfo')) || {}, // 获取localStorage中的info
    };
  },
  mounted() {
    // 接收批量上传接口上传成功后返回的值
    this.$bus.$on("uploadSuccess", async (from, data) => {
      if (from === 'material_image') {
        this.picMaterialCreate(data)
      }
    });
  },
  beforeDestroy () {
    this.$bus.$off('uploadSuccess');
  },
  methods: {
    // 上传图片
    openUploadModal() {
      this.$bus.$emit('openUploadFile', {from:'material_image',type:'image'})
    },
     // 批量新增图片
    async picMaterialCreate(value) {
      this.loading = true
      const params = value.map((item) => {
        return {
          picName: item.fileName,
          picUrl: item.downUrl,
        }
      })
      const data = await this.API.materialCenter.picMaterialCreate(params);
      if (data && data instanceof Object && data.code === 200) {
        this.getImageList()
      }
    },
    show() {
      this.isVisible = true;
      if (this.imageList.length == 0) {
        this.getImageList();
      }
    },
    //图片弹框关闭
    imageBeforeClose() {
      this.isVisible = false;
      this.choosedImageUrl = "";
    },
    //图片弹框确认
    async imageConfirm() {
      this.$emit("onImageChoose", {
        picUrl: this.choosedImageUrl,
        picName: this.imageList.filter(item => item.picUrl === this.choosedImageUrl)[0].picName,
      });
      this.imageBeforeClose();
    },
    //获取列表
    async getImageList() {
        this.loading = true
        const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
        };
        try {
            const data = await this.API.materialCenter.picMaterialList(params);
            this.imageList = data.data.list;
            this.total = data.data.total;
            this.loading = false
        } catch (error) {
            this.loading = false
        }
    },
    // 分页
    handleCurrentChange(val) {
        this.pageNum = val
        this.getImageList()
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    display: flex;
    justify-content: center;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
/deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .el-radio-button {
        margin-right: 14px;
        margin-bottom: 14px;
        .el-radio-button__inner {
            border-left: 1px solid #DCDFE6;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: content-box;
            box-shadow: unset;
            background: #F7F8FA;
            .v_img {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 198px;
              width: 198px;
              overflow: hidden;
              > img {
                max-width: 100%;
                max-height: 100%;
              }
            }
             > span {
                font-size: 12px;
                color: #9093A0;
                margin-top: 12px;
            }
            &:hover {
                border-color: #FF7500;
                background: #FFF2E6;
            }
        }
        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            border-color: #FF7500;
            background: #FFF2E6;
        }
    }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    color: #FF7500;
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
</style>