<template>
    <el-dialog
      :visible.sync="isDialogVisible"
      title="添加资讯"
      width="920px"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
      center
    >
        <div class="dlg-content">
            <el-form ref="form" label-width="84px" :model="form">
                <el-form-item label="选择股票:">
                    <el-select
                        v-model="form.stock_code"
                        placeholder="请选择股票"
                        filterable
                        remote
                        :remote-method="searchStock"
                        :loading="loading"
                    >
                        <el-option
                            v-for="item in stockArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="互动描述:">
                    <el-input v-model="form.advice_des" placeholder="股票名称-资讯标题" maxlength="12" show-word-limit></el-input>
                </el-form-item>
                <el-radio-group v-model="form.advice_id">
                    <el-tabs v-model="activeTab">
                        <el-tab-pane label="新闻" name="1" lazy>
                            <van-empty :description="!form.stock_code ? '请选择股票' : '暂无数据'" v-if="!form.stock_code || !newsObj.press.length"></van-empty>
                            <div v-else class="content_new">
                                <el-radio-button :label="item.iAutoID" v-for="item in newsObj.press" :key="item.iAutoID">
                                    <div class="press">
                                        <h4 :title="item.sTitle">{{ item.sTitle }}</h4>
                                        <p :title="item.sDescription">{{ item.sDescription }}</p>
                                        <div>
                                            <ul v-if="!item.vtTagInfo[0] || !item.vtTagInfo[0].sTagName"></ul>
                                            <ul v-else class="news_tag news_tag_inlist">
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 5 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 5 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 4 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 4 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 3 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 3 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 2 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 2 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 1 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 1 ? 'none' : 'block'}"></i>
                                                </li>
                                            </ul>
                                            <div>
                                                <span>{{ item.sFrom }}</span>
                                                <span>{{ $moment(Number(`${item.iCreateTime}000`)).format('MM-DD') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-radio-button>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="研报" name="2" lazy>
                            <van-empty :description="!form.stock_code ? '请选择股票' : '暂无数据'" v-if="!form.stock_code || !newsObj.report.length"></van-empty>
                            <div v-else class="content_new">
                                <el-radio-button :label="item.iAutoID" v-for="item in newsObj.report" :key="item.iAutoID">
                                    <div class="press">
                                        <h4 :title="item.sTitle">{{ item.sTitle }}</h4>
                                        <p :title="item.sDescription">{{ item.sDescription }}</p>
                                        <div>
                                            <ul v-if="!item.vtTagInfo[0] || !item.vtTagInfo[0].sTagName"></ul>
                                            <ul v-else class="news_tag news_tag_inlist">
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 5 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 5 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 4 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 4 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 3 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 3 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 2 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 2 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 1 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 1 ? 'none' : 'block'}"></i>
                                                </li>
                                            </ul>
                                            <div>
                                                <span>{{ item.sFrom }}</span>
                                                <span>{{ $moment(Number(`${item.iCreateTime}000`)).format('MM-DD') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-radio-button>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="公告" name="3" lazy>
                            <van-empty :description="!form.stock_code ? '请选择股票' : '暂无数据'" v-if="!form.stock_code || !newsObj.notice.length"></van-empty>
                            <div v-else class="content_new">
                                <el-radio-button :label="item.iAutoID" v-for="item in newsObj.notice" :key="item.iAutoID">
                                    <div class="press">
                                        <h4 :title="item.sTitle">{{ item.sTitle }}</h4>
                                        <p :title="item.sDescription">{{ item.sDescription }}</p>
                                        <div>
                                            <ul v-if="!item.vtTagInfo[0] || !item.vtTagInfo[0].sTagName"></ul>
                                            <ul v-else class="news_tag news_tag_inlist">
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 5 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 5 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 4 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 4 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 3 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 3 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 2 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 2 ? 'none' : 'block'}"></i>
                                                </li>
                                                <li>
                                                    <span
                                                        :style="{ display: item.vtTagInfo[0].eAttiType === 1 ? 'block' : 'none'}"
                                                    >
                                                        {{ item.vtTagInfo[0].sTagName }}
                                                    </span>
                                                    <i :style="{ display: item.vtTagInfo[0].eAttiType === 1 ? 'none' : 'block'}"></i>
                                                </li>
                                            </ul>
                                            <div>
                                                <span>{{ item.sFrom }}</span>
                                                <span>{{ $moment(Number(`${item.iCreateTime}000`)).format('MM-DD') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-radio-button>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <!-- <el-radio-button :label="item.id" v-for="item in toolList" :key="item.id">
                        <img :src="item.picUrl" alt="">
                        <span :title="item.title">{{ item.title }}</span>
                    </el-radio-button> -->
                </el-radio-group>
            </el-form>
        </div>
        <div class="dlg-footer" slot="footer">
            <el-button @click="beforeClose">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { getStock } from '@/api/upload'

export default {
    data: function() {
        return {
            isDialogVisible: false,
            activeTab: '1',
            newsObj: {
                press: [],
                report: [],
                notice: [],
            },
            stockArr: [], // 处理之后的股票列表
            loading: false,
            form: {
                stock_code: '',
                advice_des: '',
                advice_id: ''
            },
            defaultValue: {},
        }
    },
    created() {
        this.handleStockList()
        this.getNewList()
    },
    watch: {
        // 当切换信息
        activeTab() {
            if ((Number(this.activeTab) === 1 && this.newsObj.press.length === 0) || (Number(this.activeTab) === 2 && this.newsObj.report.length === 0) || (Number(this.activeTab) === 3 && this.newsObj.notice.length === 0)) {
                this.getNewList()
            }
        },
        // 当切换股票的时候
         "form.stock_code": {
            handler(newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                     this.newsObj = {
                        press: [],
                        report: [],
                        notice: [],
                    }
                    this.getNewList()
                }
            },
        },
        "form.advice_id": {
            handler(newValue, oldValue) {
                 if (newValue) {
                     let des = ''
                     if (this.activeTab === '1') {
                         des = this.newsObj.press.filter(item => item.iAutoID === newValue)[0].sTitle.slice(0,12)
                     }
                     if (this.activeTab === '2') {
                         des = this.newsObj.report.filter(item => item.iAutoID === newValue)[0].sTitle.slice(0,12)
                     }
                     if (this.activeTab === '3') {
                         des = this.newsObj.notice.filter(item => item.iAutoID === newValue)[0].sTitle.slice(0,12)
                     }
                     this.form = {
                         ...this.form,
                         advice_des: des
                     }
                }
            },
        },
    },
    methods: {
        getNewList() {
            if (this.form.stock_code) {
                this.API.getNews({
                    // sDtSecCode: isNaN(Number(this.form.stock_code)) ? this.defaultValue.stock_num : this.form.stock_code,
                    sDtSecCode: this.form.stock_code,
                    eNewsType: Number(this.activeTab),
                }).then((data) => {
                    let arr = JSON.parse(data.content).stNewsList.vNewsDesc
                    if (Number(this.activeTab) === 1) {
                        this.newsObj = {
                            ...this.newsObj,
                            press: arr,
                        }
                    } else if (Number(this.activeTab) === 2) {
                        this.newsObj = {
                            ...this.newsObj,
                            report: arr,
                        }
                    } else {
                        this.newsObj = {
                            ...this.newsObj,
                            notice: arr,
                        }
                    }
                })
            }
        },
        handleStockList(value) {
            if (value && value instanceof Array && value.length > 0) {
                this.stockArr = value.map((item) => {
                    return {
                        label: item.sCHNShortName,
                        value: item.sDtSecCode,
                    }
                })
            }
        },
        searchStock(value) {
            this.loading = true
            getStock({
                key: value,
                type: 0
            }).then((res) => {
                this.loading = false
                if (res && res instanceof Object && res.vStockItem && res.vStockItem.length > 0) {
                    this.handleStockList(res.vStockItem)
                }
            })
        },
        tabClick(value) {
            console.log(value)
        },
        show(value) {
            this.isDialogVisible = true
            if (value) {
                this.form.stock_code = value.stock_name
                this.defaultValue = value
            }
        },
        confirm() {
            let des = ''
            let params = {}
            if (this.activeTab === '1') {
                des = this.newsObj.press.filter(item => item.iAutoID === this.form.advice_id)[0]
            }
            if (this.activeTab === '2') {
                des = this.newsObj.report.filter(item => item.iAutoID === this.form.advice_id)[0]
            }
            if (this.activeTab === '3') {
                des = this.newsObj.notice.filter(item => item.iAutoID === this.form.advice_id)[0]
            }
            if (isNaN(Number(this.form.stock_code))) {
                params = {
                    form: {
                        ...this.form,
                        stock_code: this.defaultValue.stock_num
                    },
                     adviceInfo: des
                };
                
            } else {
                params = {
                    form: this.form,
                    adviceInfo: des
                };
            }
            this.$emit('onAdviceChoose', params)
            this.beforeClose()
        },
        // 关闭弹框
        beforeClose() {
            this.isDialogVisible = false
            this.loading = false
            this.stockArr = []
            this.form = {
                stock_code: '',
                advice_des: '',
            }
        },
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}

.el-select {
    width: 100%;
}
/deep/ .el-tabs__active-bar {
    width: 18px !important;
    margin-left: 4px;
    height: 4px;
}
/deep/ .el-tabs__item {
    color: #8D8DA7;
    font-weight: bold;
    height: 36px;
}
/deep/ .el-tabs__item.is-active {
    color: #252525;
    font-weight: bold;
}
/deep/ .el-tabs__nav-wrap::after {
    height: 0;
}
.dlg-footer {
    display: flex;
    justify-content: flex-end;
}
.el-radio-group {
    width: 100%;
}
.content_new {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    min-height: 260px;
    max-height: 360px;
    overflow-y: scroll;
    // overflow-x: hidden;
    /deep/ .el-radio-button {
        margin-bottom: 14px;
        width: 48%;
        overflow: hidden;
        .el-radio-button__inner {
            border-left: 1px solid #DCDFE6;
            padding: 12px;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            box-sizing: content-box;
            box-shadow: unset;
            background: #F7F8FA;
            height: 90px;
            &:hover {
                border-color: #FF7500;
                background: #FFF2E6;
            }
            // 新闻 // 研报 // 公告
            .press {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                > h4 {
                   color: #252525;
                   text-align: left;
                   overflow: hidden;
                   text-overflow: ellipsis;
                   white-space: nowrap;
                }
                > p {
                    width: 100%;
                    color: #6A6C77;
                    font-size: 12px;
                    line-height: 16px;
                    margin: 8px 0 14px 0;
                    overflow : hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: pre-wrap;
                    text-align: left;
                }
                > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #6A6C77;
                    > div {
                        span {
                            margin-left: 12px;
                        }
                    }
                    .news_tag {
                        li {
                            float: left;
                            background: 0 0!important;
                            span {
                                float: left;
                                height: 14px;
                                line-height: 12px;
                                padding: 2px 5px 0;
                                font-size: 9px;
                                color: #fff;
                                display: none;
                                box-sizing: border-box;
                                -webkit-box-sizing: border-box;
                                border-radius: 2px;
                                vertical-align: middle;
                                display: none;
                            }
                            i {
                                float: left;
                                width: 24px;
                                height: 6px;
                                margin-top: 4px;
                            }
                        }
                    }
                    .news_tag li:nth-child(1) {
                        i {
                            border: 7px 0 0 7px;
                            background-color: #2a8e29;
                        }
                        span {
                            padding-top: 1px;
                            background-color: #2a8e29;
                        }
                    }
                    .news_tag li:nth-child(2) {
                        i {
                            background-color: #4cba2a;
                        }
                        span {
                            padding-top: 1px;
                            background-color: #4cba2a;
                        }
                    }
                    .news_tag li:nth-child(3) {
                        i {
                            background-color: #c5d106;
                        }
                        span {
                            padding-top: 1px;
                            background-color: #c5d106;
                        }
                    }
                    .news_tag li:nth-child(4) {
                        i {
                            background-color: #ff9800;
                        }
                        span {
                            background-color: #ff9800;
                            padding-top: 1px;
                        }
                    }
                    .news_tag li:nth-child(5) {
                        i {
                            border: 0 7px 7px 0;
                            background-color: #ea4410;
                        }
                        span {
                            padding-top: 1px;
                            background-color: #ea4410;
                        }
                    }
                }
            }
        }
        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            border-color: #FF7500;
            background: #FFF2E6;
        }
    }
}
</style>