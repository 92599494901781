<template>
    <div class="t_words">
        <span v-for="(item, index) in newValue" :key="index">
            <el-popover placement="left" width="180" trigger="hover" v-if="item.type === 'ele'">
                <ul class="add_list">
                    <li @click="addGoods(editIndex)"><i class="el-icon-shopping-cart-2"></i>商品</li>
                    <li @click="addTools(editIndex, getAttr(item.data))"><i class="el-icon-setting"></i>工具</li>
                    <li @click="addMarket(editIndex, getAttr(item.data))"><i class="el-icon-data-line"></i>行情</li>
                    <li @click="addAdvice(editIndex, getAttr(item.data))"><i class="el-icon-news"></i>资讯</li>
                </ul>
                <span slot="reference" v-html="item.data"></span>
            </el-popover>
            <span v-else>{{ item.data }}</span>
        </span>
    </div> 
</template>
<script>
export default {
    props: {
        editIndex: {
            type: Number,
            default: 0,
        },
        value: {
            type: String,
            default: ''
        },
        addGoods: {
            type: Function,
            default: function() {}
        },
        addTools: {
            type: Function,
            default: function() {}
        },
        addMarket: {
            type: Function,
            default: function() {}
        },
        addAdvice: {
            type: Function,
            default: function() {}
        },
    },
    data: function() {
        return {
            newValue: []
        }
    },
    created() {
        this.regexpChange(this.value)
    },
    methods: {
        // 给识别到的标签添加popover
        regexpChange(content) {
            const eleArr = [...content.matchAll(/<a[\s\S]*?<\/a>/ig)].map(item => item[0])
            const strArr = content.replaceAll(/<a[\s\S]*?<\/a>/ig, '&&').split('&&')
            if (eleArr && eleArr.length > 0) {
                strArr.forEach((item, index) => {
                    this.newValue.push({
                        type: 'txt',
                        data: item
                    })
                    if (eleArr[index]) {
                        this.newValue.push({
                            type: 'ele',
                            data: eleArr[index]
                        })
                    }
                })
            } else {
                this.newValue.push({
                    type: 'txt',
                    data: content
                })
            }
        },
        getAttr(str) {
            let stockName = /data-secname="\W+"/ig.exec(str)[0].split('=')[1]
            return {
                stock_num: /[\d]+/ig.exec(str)[0],
                stock_name: stockName.substring(1, stockName.length - 1)
            }
        }
    },
}
</script>
<style lang="less" scoped>
.add_list {
  font-size: 16px;
  margin: -10px;

  li {
    cursor: pointer;
    padding: 10px;
    background: #f2f2f2;
    margin-bottom: 1px;

    i {
      margin-right: 4px;
    }

    &:hover {
      background: #eee4bd;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>