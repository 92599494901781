<template>
    <el-dialog
      :visible.sync="isDialogVisible"
      title="添加分支视频"
      width="1110px"
      top="6vh"
      :closeOnClickModal="false"
      :before-close="videoBeforeClose"
      append-to-body
    >
        <div class="video_list" v-loading="loading">
            <div class="filter">
                <el-tabs v-model="activeTab" @tab-click="handleTabClick">
                    <el-tab-pane label="本地上传" name="本地上传"></el-tab-pane>
                    <!-- <el-tab-pane label="视频素材" name="视频素材"></el-tab-pane> -->
                    <el-tab-pane label="推荐视频" name="推荐视频"></el-tab-pane>
                    <el-tab-pane label="行情回顾" name="行情回顾"></el-tab-pane>
                    <el-tab-pane label="财务报告" name="财务报告"></el-tab-pane>
                    <el-tab-pane label="舆情日报" name="舆情日报"></el-tab-pane>
                    <el-tab-pane label="个股诊断" name="个股诊断"></el-tab-pane>
                    <el-tab-pane label="产业链" name="产业链"></el-tab-pane>
                </el-tabs>
                <div class="inputSearch" v-if="['本地上传', '推荐视频', '产业链'].indexOf(activeTab) !== -1">
                    <el-input placeholder="输入要查找的内容" v-model="keyword" @change="searchKeyword" clearable>
                    <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
                    </el-input>
                </div>
                <el-select
                    v-if="['行情回顾', '财务报告', '舆情日报', '个股诊断'].indexOf(activeTab) !== -1"
                    v-model="keyword"
                    placeholder="输入要查找的内容"
                    filterable
                    remote
                    clearable
                    :remote-method="searchStock"
                    :loading="selectLoading"
                    @change="changeValue"
                >
                    <el-option
                        v-for="item in stockArr"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <el-radio-group v-model="choosedVideoUrl">
                <el-radio-button :label="item.videoUrl" v-for="(item, index) in videoList" :key="index">
                   <div class="v_video">
                        <div class="v_img">
                            <img :src="item.picUrl" alt="" />
                        </div>
                   </div>
                </el-radio-button>
            </el-radio-group>
            <div class="pagination" v-show="total > pageSize">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <span v-if="activeTab==='本地上传'" @click="addVideo">新增视频</span>
            <span v-else></span>
            <div>
                <el-button @click="videoBeforeClose">取 消</el-button>
                <el-button @click="videoConfirm" type="primary">确 定</el-button>
            </div>
        </span>
    </el-dialog>
</template>
<script>
import { getStock } from '@/api/upload'

export default {
    data: function() {
        return {
            isDialogVisible: false,
            choosedVideoUrl: '',
            activeTab: "本地上传",
            model_id: undefined,
            loading: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            keyword: '',
            selectLoading: false,
            stockArr: [], // 股票列表
            videoList: [], //互动视频列表
        }
    },
     mounted() {
        // 接收批量上传接口上传成功后返回的值
        this.$bus.$on("uploadSuccess", async (from, data) => {
            if (from === 'material_video') {
                this.branchVideoCreate(data)
            }
        });
    },
    beforeDestroy () {
        this.$bus.$off('uploadSuccess');
    },
    methods: {
        show() {
            this.isDialogVisible = true
            if (this.videoList.length === 0) {
                this.getVideoList();
            }
        },
        addVideo() {
            this.$bus.$emit('openUploadFile', {from:'material_video',type:'video'})
        },
        videoBeforeClose() {
            this.isDialogVisible = false
            this.choosedVideoUrl = ''
        },
        videoConfirm() {
            this.$emit("onBranchVideoChoose", {
                choosedBranchVideoUrl: this.choosedVideoUrl,
                choosedBranchTitle: this.videoList.filter(item => item.videoUrl === this.choosedVideoUrl)[0].videoName,
            });
            this.videoBeforeClose();
        },
        handleTabClick() {
            switch (this.activeTab) {
            case '行情回顾':
                this.model_id = 9
                break;
            case '财务报告':
                this.model_id = 200
                break;
            case '舆情日报':
                this.model_id = 199
                break;
            case '个股诊断':
                this.model_id = 14
                break;
            case '产业链':
                this.model_id = 100
                break;
            default:
                this.model_id = undefined
                break;
            }
            this.initData()
            this.getVideoList()
        },
        initData() {
            this.pageNum = 1
            this.total = 0
            this.videoList = []
        },
        // 分页
        handleCurrentChange(val) {
            this.pageNum = val
            this.getVideoList()
        },
        // 本地视频搜索
        searchKeyword() {
            this.pageNum = 1;
            this.getVideoList()
        },
        changeValue() {
            this.pageNum = 1;
            this.getVideoList()
        },
        searchStock(value) {
            this.selectLoading = true
            getStock({
                key: value,
                type: 0
            }).then((res) => {
                this.selectLoading = false
                if (res && res instanceof Object && res.vStockItem && res.vStockItem.length > 0) {
                    this.handleStockList(res.vStockItem)
                }
            })
        },
        handleStockList(value) {
            if (value && value instanceof Array && value.length > 0) {
                this.stockArr = value.map((item) => {
                    return {
                        label: item.sCHNShortName,
                        value: item.sDtSecCode.slice(4),
                    }
                })
            }
        },
        //获取列表
        async getVideoList() {
            this.loading = true
            try {
                if (this.activeTab === '本地上传') {
                let data = await this.API.materialCenter.branchVideoList({
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    videoName: this.keyword || undefined
                });
                    this.videoList = data.data.list;
                    this.total = data.data.total;
                } else {
                    let data = await this.API.materialCenter.videoresultsearch({
                        page: this.pageNum,
                        size: this.pageSize,
                        model_id: this.model_id,
                        keyword: this.keyword || undefined
                    })
                    this.videoList = data.data.map((item) => {
                        return {
                            picUrl: item.video_image_url,
                            videoName: item.stock_name || item.article_title,
                            videoUrl: item.article_content
                        }
                    })
                    this.total = data.counts
                }
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        // 调用上传视频接口
        async branchVideoCreate(value) {
            this.loading = true
            const params = value.map((item) => {
                return {
                    smallPicUrl: item.smallPicUrl, // 可以不传，暂时没意义
                    picUrl: item.picUrl,
                    videoName: item.fileName,
                    videoUrl: item.downUrl
                }
            })
            const data = await this.API.materialCenter.branchVideoCreate(params);
            if (data && data instanceof Object && data.code === 200) {
                this.getVideoList()
            }
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    display: flex;
    justify-content: center;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
/deep/ .el-dialog__body {
    padding: 0 20px;
    .video_list {
        min-height: 710px;
        .filter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .inputSearch {
                width: 22%;
                margin: 12px 14px;
            }
        }
    }
}
/deep/ .el-select {
    margin: 12px 14px;
    width: 22%;
}
/deep/ .el-tabs {
    padding-left: 24px;
    .el-tabs__header {
    margin-bottom: 10px;
    }
    .el-tabs__active-bar {
    width: 18px !important;
    margin-left: 18px;
    height: 4px;
    }
    .el-tabs__item {
    color: #8d8da7;
    font-weight: bold;
    height: 36px;
    }
    .el-tabs__item.is-active {
    color: #252525;
    font-weight: bold;
    }
    .el-tabs__nav-wrap::after {
    height: 0;
    }
}
/deep/ .el-dialog__footer {
    padding-top: 0;
}

/deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .el-radio-button {
        margin-right: 14px;
        margin-bottom: 14px;
        .el-radio-button__inner {
            border-left: 1px solid #DCDFE6;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: content-box;
            box-shadow: unset;
            background: #F7F8FA;
            .v_video {
                .v_img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 300px;
                    width: 198px;
                    overflow: hidden;
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &:hover {
                border-color: #FF7500;
                background: #FFF2E6;
            }
        }
        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            border-color: #FF7500;
            background: #FFF2E6;
        }
    }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    color: #FF7500;
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
}
</style>