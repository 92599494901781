<template>
    <el-dialog
      :visible.sync="isDialogVisible"
      title="添加商品"
      width="920px"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
      center
    >
        <div class="dlg-content">
            <el-form ref="form" label-width="84px" :model="form">
                <el-form-item label="商品名称:">
                    <el-radio-group v-model="form.good_index" @change="selectGood">
                        <el-radio-button :label="`${item.sName}_${index}`" v-for="(item, index) in goodList" :key="index">
                            <img :src="item.sIcon" alt="">
                            <div>
                                <span :title="item.sName">{{ item.sName }}</span>
                                <span :title="item.sDesc">{{ item.sDesc }}</span>
                            </div>
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="商品名称:">
                    <el-input v-model="form.good_name" maxlength="8" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="商品描述:">
                    <el-input v-model="form.good_des" maxlength="8" show-word-limit></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="dlg-footer" slot="footer">
            <el-button @click="beforeClose">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            isDialogVisible: false,
            goodList: [],
            form: {
                good_index: '',
                good_name: '',
                good_des: '',
            },
        }
    },
    // created() {
    //     this.getGoodList()
    // },
    methods: {
        async getGoodList() {
            let promise = [];
            let dataSource = []
            promise.push(this.API.goods.wedengta().then((res) => {
                dataSource = dataSource.concat(JSON.parse(res.content).vList)
            }));
            promise.push(this.API.goods.chinahxzq().then((res) => {
                dataSource = dataSource.concat(JSON.parse(res.content).vList)
            }).catch(() => {}));
            promise.push(this.API.goods.dtsbc().then((res) => {
                dataSource = dataSource.concat(JSON.parse(res.content).vList)
            }).catch(() => {}));
            Promise.all(promise).then(() => {
                // 过滤掉华鑫的活动产品(打折产品)
                this.goodList = dataSource.filter(item => item.iType != 10053 && item.iType != 10056 && item.iType != 10057 && item.iType != 10062 && item.iType != 10063)
            })
        },
        show() {
            this.isDialogVisible = true
            if (this.goodList.length === 0) {
                this.getGoodList()
            }
        },
        selectGood(value) {
            let goodInfo = this.goodList[value.split('_')[1]]
            this.form = Object.assign({}, this.form, {
                good_name: goodInfo.sName,
                good_des: goodInfo.sDesc,
            })
        },
        confirm() {
            let params = {
                form: this.form,
                goodInfo: this.goodList[this.form.good_index.split('_')[1]]
            };
            this.$emit('onGoodChoose', params)
            this.beforeClose()
        },
        // 关闭弹框
        beforeClose() {
            this.isDialogVisible = false
            this.form = {
                good_index: '',
                good_name: '',
                good_des: '',
            }
        },
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
/deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .el-radio-button {
        margin-right: 14px;
        margin-bottom: 14px;
        .el-radio-button__inner {
            border-left: 1px solid #DCDFE6;
            padding: 12px;
            border-radius: 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            box-sizing: content-box;
            box-shadow: unset;
            background: #F7F8FA;
            > img {
                height: 56px;
                width: auto;
            }
            > div {
                width: 92px;
                height: 100%;
                text-align-last: left;
                margin-left: 8px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                > span {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #252525;
                }
                > span:last-child {
                    font-size: 12px;
                    color: #9093A0;
                }
            }
            &:hover {
                border-color: #FF7500;
                background: #FFF2E6;
            }
        }
        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            border-color: #FF7500;
            background: #FFF2E6;
        }
    }
}
.dlg-footer {
    display: flex;
    justify-content: flex-end;
}
</style>