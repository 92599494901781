<template>
    <el-dialog
      :visible.sync="isDialogVisible"
      title="添加工具"
      width="920px"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
      center
    >
        <div class="dlg-content">
            <el-form ref="form" label-width="84px" :model="form">
                <el-form-item label="工具名称:">
                    <el-radio-group v-model="form.tool_index">
                        <el-radio-button :label="item.id" v-for="item in toolList" :key="item.id">
                            <img :src="item.picUrl" alt="">
                            <span :title="item.title">{{ item.title }}</span>
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="选择股票:">
                    <el-select
                        v-model="form.stock_code"
                        placeholder="请选择股票"
                        filterable
                        remote
                        :remote-method="searchStock"
                        :loading="loading"
                    >
                        <el-option
                            v-for="item in stockArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="互动描述:">
                    <el-input v-model="form.stock_des" placeholder="股票名称-工具名称" maxlength="12" show-word-limit></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="dlg-footer" slot="footer">
            <el-button @click="beforeClose">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { getStock } from '@/api/upload'
export default {
    data: function() {
        return {
            isDialogVisible: false,
            stockArr: [], // 处理之后的股票列表
            toolList: [],
            loading: false,
            form: {
                tool_index: null,
                stock_code: '',
                stock_des: '',
            },
            defaultValue: {},
        }
    },
    watch: {
        "form.stock_code": {
            handler(newValue, oldValue) {
                if (newValue && this.form.tool_index !== null) {
                    let toolName = this.toolList.filter(item => item.id === this.form.tool_index)[0].title
                    let stockName = this.stockArr.filter(item => item.value === newValue)[0].label
                    this.form = {
                        ...this.form,
                        stock_des: `${stockName}-${toolName}`
                    }
                }
            },
        },
        "form.tool_index": {
            handler(newValue, oldValue) {
                if (this.form.stock_code && newValue !== null) {
                    let toolName = this.toolList.filter(item => item.id === newValue)[0].title
                    let stockName = this.stockArr.length > 0 ? this.stockArr.filter(item => item.value === this.form.stock_code)[0].label : this.form.stock_code
                    this.form = {
                        ...this.form,
                        stock_des: `${stockName}-${toolName}`
                    }
                }
            },
        },
    },
    // created() {
    //     this.getToolList()
    // },
    methods: {
        handleStockList(value) {
            if (value && value instanceof Array && value.length > 0) {
                this.stockArr = value.map((item) => {
                    return {
                        label: item.sCHNShortName,
                        value: item.sDtSecCode,
                    }
                })
            }
        },
        getToolList() {
            this.API.getToolList({
                pageNum: 1,
                pageSize: 100,
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.toolList = res.data.list || []
                }
            })
        },
        searchStock(value) {
            this.loading = true
            getStock({
                key: value,
                type: 0
            }).then((res) => {
                this.loading = false
               if (res && res instanceof Object && res.vStockItem && res.vStockItem.length > 0) {
                   this.handleStockList(res.vStockItem)
               }
            })
        },
        async show(value) {
            this.isDialogVisible = true
            if (this.toolList.length === 0) {
                this.getToolList()
            }
            if (value && value instanceof Object && Object.keys(value).length > 0) {
               this.form.stock_code = value.stock_name
               this.defaultValue = value
            }
        },
        confirm() {
            if (this.form.stock_code) {
                let params = {}
                if (isNaN(Number(this.form.stock_code))) {
                    params = {
                        form: {
                            ...this.form,
                            stock_code: this.defaultValue.stock_num
                        },
                        toolInfo: this.toolList.filter(item => item.id === this.form.tool_index)[0]
                    };
                    
                } else {
                    params = {
                        form: this.form,
                        toolInfo: this.toolList.filter(item => item.id === this.form.tool_index)[0]
                    };
                }
                this.$emit('onToolChoose', params)
                this.beforeClose()
            } else {
                this.$message.warning('请选择股票！')
            }
        },
        // 关闭弹框
        beforeClose() {
            this.isDialogVisible = false
            this.stockArr = []
            this.loading = false
            this.form = {
                tool_index: null,
                stock_code: '',
                stock_des: '',
            }
        },
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
/deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .el-radio-button {
        margin-right: 14px;
        margin-bottom: 14px;
        .el-radio-button__inner {
            border-left: 1px solid #DCDFE6;
            padding: 12px;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            box-sizing: content-box;
            box-shadow: unset;
            background: #F7F8FA;
            height: 80px;
            > img {
                height: 56px;
                width: auto;
            }
            > span {
                color: #9093A0;
                font-size: 12px;
            }
            &:hover {
                border-color: #FF7500;
                background: #FFF2E6;
                > span {
                    color: #252525;
                }
            }
        }
        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            border-color: #FF7500;
            background: #FFF2E6;
            > span {
                color: #252525;
            }
        }
    }
}
.el-select {
    width: 100%;
}
.dlg-footer {
    display: flex;
    justify-content: flex-end;
}
</style>