<template>
    <el-dialog
      :visible.sync="isDialogVisible"
      title="添加行情"
      width="920px"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
      center
    >
        <div class="dlg-content">
            <el-form ref="form" label-width="84px" :model="form">
                <el-form-item label="选择股票:">
                    <el-select
                        v-model="form.stock_code"
                        placeholder="请选择股票"
                        filterable
                        remote
                        :remote-method="searchStock"
                        :loading="loading"
                    >
                        <el-option
                            v-for="item in stockArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="行情名称:">
                    <el-input v-model="form.market_name" placeholder="股票名称-行情" maxlength="12" show-word-limit></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="dlg-footer" slot="footer">
            <el-button @click="beforeClose">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { getStock } from '@/api/upload'
export default {
    data: function() {
        return {
            isDialogVisible: false,
            stockArr: [], // 处理之后的股票列表
            loading: false,
            form: {
                stock_code: '',
                market_name: '',
            },
            defaultValue: {},
        }
    },
    watch: {
        "form.stock_code": {
            handler(newValue, oldValue) {
                if (newValue) {
                    let stockName = this.stockArr.length > 0 ? this.stockArr.filter(item => item.value === newValue)[0].label : this.form.stock_code
                    this.form = {
                        ...this.form,
                        market_name: `${stockName}-行情`
                    }
                }
            },
        },
    },
    created() {
        this.handleStockList()
    },
    methods: {
        handleStockList(value) {
            if (value && value instanceof Array && value.length > 0) {
                this.stockArr = value.map((item) => {
                    return {
                        label: item.sCHNShortName,
                        value: item.sDtSecCode,
                    }
                })
            }
        },
        searchStock(value) {
            this.loading = true
            getStock({
                key: value,
                type: 0
            }).then((res) => {
                this.loading = false
               if (res && res instanceof Object && res.vStockItem && res.vStockItem.length > 0) {
                   this.handleStockList(res.vStockItem)
               }
            })
        },
        show(value) {
            this.isDialogVisible = true
            if (value && value instanceof Object && Object.keys(value).length > 0) {
               this.form.stock_code = value.stock_name
               this.form.market_name = `${value.stock_name}-行情`
               this.defaultValue = value
            }
        },
        confirm() {
            let params = {}
            if (isNaN(Number(this.form.stock_code))) {
                params = {
                    form: {
                        ...this.form,
                        stock_code: this.defaultValue.stock_num
                    },
                    marketInfo: this.stockArr.length > 0 ? this.stockArr.filter(item => item.value === this.form.stock_code)[0] : {
                        value: this.defaultValue.stock_num,
                        label: this.defaultValue.stock_name,
                    }
                };
                
            } else {
                params = {
                    form: this.form,
                    marketInfo: this.stockArr.filter(item => item.value === this.form.stock_code)[0]
                };
            }
            this.$emit('onMarketChoose', params)
            this.beforeClose()
        },
        // 关闭弹框
        beforeClose() {
            this.isDialogVisible = false
            this.stockArr = []
            this.loading = false
            this.form = {
                stock_code: '',
                market_name: '',
            }
        },
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
.el-form {
    display: flex;
    justify-content: space-between;
}
.dlg-footer {
    display: flex;
    justify-content: flex-end;
}
</style>