<template>
    <el-dialog
      :visible.sync="isDialogVisible"
      title="新增投票"
      width="920px"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
    >
        <el-form ref="form" :model="form" label-width="112px">
            <el-form-item label="投票名称:" prop="voteTitle" :rules="[{ required: true, message: '请输入投票名称', trigger: 'blur' }]">
                <el-input v-model="form.voteTitle" placeholder="请输入投票名称" />
            </el-form-item>
            <el-form-item label="投票截止时间:" prop="endDate" :rules="[{ required: true, message: '请选择投票截止时间', trigger: 'blur' }]">
                <el-date-picker v-model="form.endDate" type="datetime" placeholder="请选择投票截止时间" />
            </el-form-item>
            <el-form-item :label="'选项' + transTxt(index) + ':'" v-for="(item, index) in defaultOptions" :key="index">
                <div class="operation">
                <el-input v-model="form.newOptions[index]" placeholder="请输入选项" />
                <i class="el-icon-delete" v-if="index > 1" @click="deleteOption(index)"></i>
                </div>
            </el-form-item>
            <div class="operation">
                <el-form-item label="是否单选:">
                <el-switch v-model="form.isRadio" />
                </el-form-item>
                <div :class="{ add_option: true, disabled: defaultOptions.length > 3 }" @click="addOption">添加选项</div>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="beforeClose">取 消</el-button>
            <el-button @click="voteConfirm" type="primary">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            isDialogVisible: false,
            form: {
                voteTitle: "",
                endDate: null,
                isRadio: true, //是否单选
                newOptions: ["", ""]
            },
            defaultOptions: ["", ""],
        }
    },
    methods: {
        show() {
            this.isDialogVisible = true
        },
        // 关闭弹框
        beforeClose() {
            Object.assign(this.$data, this.$options.data())
        },
        voteConfirm() {
            const _that = this
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    if (!(_that.form.newOptions.filter(item => item.length > 0).length > 1)) {
                        return _that.$message({
                            type: "warning",
                            message: "选项一、选项二必须填写"
                        });
                    }
                    let voteResultStat = {};
                    _that.form.newOptions.forEach((item) => {
                        if (item != "") {
                            voteResultStat[item] = 0;
                        }
                    });
                    _that.API.materialCenter.voteInfoCreate({
                        optionArray: voteResultStat,
                        singleSelected: _that.form.isRadio ? 0 : 1, // 1|多选 0|单选
                        title: _that.form.voteTitle,
                        voteEndDate: _that.$moment(_that.form.endDate).format('YYYY-MM-DD HH:mm:ss'),
                        voteStartDate: _that.$moment().format('YYYY-MM-DD HH:mm:ss')
                    }).then((res) => {
                        if (res && res instanceof Object && res.code === 200) {
                            _that.$message.success('投票添加成功！')
                            _that.beforeClose()
                            _that.$emit('getVoteList')
                        } else {
                             _that.$message.error('投票添加失败！')
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        transTxt(index) {
            const txtArr = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
            return txtArr[index];
        },
        //增加选项
        addOption() {
            this.defaultOptions.push("");
            this.form.newOptions.push("");
        },
        //删除选项
        deleteOption(index) {
            this.defaultOptions.splice(index, 1);
            this.form.newOptions.splice(index, 1);
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    display: flex;
    justify-content: center;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
.operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-form-item {
      margin-bottom: 0;
    }
    > i {
      font-size: 18px;
      margin-left: 24px;
      cursor: pointer;
    }
    .add_option {
      padding: 16px 16px;
      font-size: 14px;
      color: #ff9226;
      display: inline-block;
      cursor: pointer;
    }

    .add_option.disabled {
      color: #ccc;
      pointer-events: none;
    }
  }
</style>