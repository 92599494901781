<template>
  <el-dialog
    title="新增报名"
    :visible.sync="isVisible"
    width="1110px"
    top="10vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="signupBeforeClose"
  >
    <div class="branch_videos_holder">
        <el-radio-group v-model="signupId">
          <el-radio-button :label="item.id" v-for="item in signupList" :key="item.id">
              <div class="signup_box">
                <h3>{{ item.title }}</h3>
                <div class="signup_total">
                  <span>0人报名</span>
                  <span>报名时间：{{ item.activityDate }} ~ {{ item.endDate }}</span>
                </div>
                <div class="likeInput" v-if="item.nameStatus === '1'">请输入姓名</div>
                <div class="likeInput" v-if="item.phoneStatus === '1'">请输入手机号</div>
                <!-- <div v-if="item.remarkStatus === '1'" type="textarea" rows="2" placeholder="请输入备注"></div> -->
                <el-button type="primary">{{item.buttonText || '立即报名'}}</el-button>
              </div>
          </el-radio-button>
        </el-radio-group>
        <div class="pagination" v-show="total > pageSize">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <span @click="openUploadModal">新增报名</span>
      <div>
        <el-button @click="signupBeforeClose">取 消</el-button>
        <el-button @click="signupConfirm" type="primary">确 定</el-button>
      </div>
    </span>
    <EditSignup ref="editSignup" @getSignupList="getSignupList" />
  </el-dialog>
</template>

<script>
import EditSignup from './editSignup.vue'
export default {
  name: "add-signup",
  data() {
    return {
        loading: false,
        pageSize: 4,
        pageNum: 1,
        total: 0,
        signupList: [],
        isVisible: false,
        signupId: undefined,
    };
  },
  components: {
    EditSignup
  },
  methods: {
    openUploadModal() {
      this.$refs.editSignup.show()
    },
    show() {
      this.isVisible = true;
      if (this.signupList.length == 0) {
        this.getSignupList();
      }
    },
    //图片弹框关闭
    signupBeforeClose() {
      this.isVisible = false;
      this.signupId = undefined;
    },
    //图片弹框确认
    async signupConfirm() {
      this.$emit('onSignupChoose', this.signupList.filter(item => item.id === this.signupId)[0])
      this.signupBeforeClose();
    },
    //获取列表
    async getSignupList() {
        this.loading = true
        const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
        };
        try {
            const data = await this.API.materialCenter.signUpInfoList(params);
            this.signupList = data.data.list;
            this.total = data.data.total;
            this.loading = false
        } catch (error) {
            this.loading = false
        }
    },
    // 分页
    handleCurrentChange(val) {
        this.pageNum = val
        this.getSignupList()
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    display: flex;
    justify-content: center;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
/deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .el-radio-button {
        margin-right: 14px;
        margin-bottom: 14px;
        .el-radio-button__inner {
            border-left: 1px solid #DCDFE6;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: content-box;
            box-shadow: unset;
            background: #F7F8FA;
            text-align: left;
            .signup_box {
              padding: 12px 12px;
              height: 222px;
              width: 508px;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              > h3 {
                margin-bottom: 12px;
                color: #1A1A34;
                font-size: 16px;
              }
              .signup_total {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                > span {
                  font-size: 12px;
                  color: #8D8DA7;
                  &:first-child {
                    margin-right: 48px;
                  }
                }
              }
              .likeInput {
                background-color: #FFF;
                background-image: none;
                border-radius: 4px;
                border: 1px solid #DCDFE6;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                font-size: inherit;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 15px;
                margin-bottom: 12px;
                color: #8D8DA7;
                transition: border-color .2s cubic-bezier(.645,.045,.355,1);
                width: 100%;
              }
              > button {
                align-self: flex-start;
              }
            }
            &:hover {
                border-color: #FF7500;
                background: #FFF2E6;
            }
        }
        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            border-color: #FF7500;
            background: #FFF2E6;
        }
    }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    color: #FF7500;
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
</style>