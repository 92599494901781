import { render, staticRenderFns } from "./editCard.vue?vue&type=template&id=0f2142b2&scoped=true&"
import script from "./editCard.vue?vue&type=script&lang=js&"
export * from "./editCard.vue?vue&type=script&lang=js&"
import style0 from "./editCard.vue?vue&type=style&index=0&id=0f2142b2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f2142b2",
  null
  
)

export default component.exports