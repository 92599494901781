<template>
    <el-dialog
      :visible.sync="isDialogVisible"
      :title="`${defaultValue ? '编辑' : '添加'}报名`"
      width="920px"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
    >
        <el-form :model="signupInfo" ref="signupInfo" label-width="110px" class="card_info_form">
            <el-form-item label="报名名称:" prop="signupTitle" :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]">
                <el-input v-model="signupInfo.signupTitle" placeholder="请输入报名名称" />
            </el-form-item>
            <el-form-item label="报名截止时间:" prop="endDate" :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]">
                <el-date-picker v-model="signupInfo.endDate" type="datetime" placeholder="请选择报名截止时间" />
            </el-form-item>
            <el-form-item label="提交按钮文案:" prop="signupBtnTxt">
                <el-input v-model="signupInfo.signupBtnTxt" placeholder="请输入提交按钮文案" />
            </el-form-item>
            <el-form-item label="留姓名:" prop="isNameRequired">
                <el-switch v-model="signupInfo.isNameRequired" />
            </el-form-item>
            <el-form-item label="手机号:" prop="isMobileRequired">
                <el-switch v-model="signupInfo.isMobileRequired" disabled />
            </el-form-item>
            <!-- <el-form-item label="留备注:" prop="isMemoRequired">
                <el-switch v-model="signupInfo.isMemoRequired" />
            </el-form-item> -->
        </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button @click="beforeClose">取 消</el-button>
            <el-button @click="signupConfirm" type="primary">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            upload_txt: '',
            isDialogVisible: false,
            defaultValue: null,
            signupInfo: {
                signupTitle: "",
                signupBtnTxt: "",
                endDate: null,
                isNameRequired: true,
                isMobileRequired: true,
                isMemoRequired: false
            },
        }
    },
    methods: {
        show(value) {
            this.isDialogVisible = true
            if (value && value instanceof Object && Object.keys(value).length > 0) {
               this.defaultValue = value
               console.log(value)
            }
        },
        // 关闭弹框
        beforeClose() {
            this.$refs.signupInfo.resetFields();
            Object.assign(this.$data, this.$options.data())
        },
        signupConfirm() {
            const _that = this
            this.$refs.signupInfo.validate(async (valid) => {
                if (valid) {
                    _that.API.materialCenter.signUpInfoCreate({
                        activityDate: _that.$moment().format('YYYY-MM-DD HH:mm:ss'),
                        endDate: _that.$moment(_that.signupInfo.endDate).format('YYYY-MM-DD HH:mm:ss'),
                        buttonText: _that.signupInfo.signupBtnTxt,
                        nameStatus: _that.signupInfo.isNameRequired ? 1 : 0, // 1|需要 0|不需要
                        phoneStatus: _that.signupInfo.isMobileRequired ? 1 : 0, // 1|需要 0|不需要
                        remarkStatus: _that.signupInfo.isMemoRequired ? 1 : 0, // 1|需要 0|不需要
                        title: _that.signupInfo.signupTitle,
                    }).then((res) => {
                        if (res && res instanceof Object && res.code === 200) {
                            _that.$message.success('报名添加成功！')
                            _that.beforeClose()
                            _that.$emit('getSignupList')
                        } else {
                             _that.$message.error('报名添加失败！')
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    display: flex;
    justify-content: center;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
</style>